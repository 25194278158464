import PropTypes from 'prop-types'

import './Image.scss'

function Image (props) {
  const className = props.class !== undefined ? props.class : ''
  const alt = props.alt

  return (
    <div className={`image ${className}`}>
      <div className="image--container">
        {(alt !== '') && <img src={process.env.PUBLIC_URL + props.src} alt={alt} />}
        {(alt === '') && <img src={process.env.PUBLIC_URL + props.src} />}
      </div>
      <Caption caption={props.caption} />
    </div>
  )
}
Image.propTypes = {
  class: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  caption: PropTypes.string
}

function Caption (props) {
  const caption = props.caption

  if (caption !== '') {
    return <p dangerouslySetInnerHTML={{ __html: caption }}></p>
  } else {
    return null
  }
}

Caption.propTypes = {
  caption: PropTypes.string
}

export default Image
