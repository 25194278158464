import PropTypes from 'prop-types'

import './Text.scss'

function Text (props) {
  const content = props.content
  const weight = props.weight !== undefined ? props.weight : ''
  const lineHeight = content === '<br/>' ? 'less-height' : ''

  return (
    <p className={`text ${weight} ${lineHeight}`} dangerouslySetInnerHTML={{ __html: content }}></p>
  )
}

Text.propTypes = {
  content: PropTypes.string,
  weight: PropTypes.string
}

export default Text
