import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Content from '../../Content.jsx'
import Title from '../../ContentItems.jsx/Title.jsx/index.jsx'
import Workdays from '../../ContentItems.jsx/Workdays.jsx/index.jsx'
import Nav from '../../Nav.jsx'

import './Card8.scss'

function Card8 (props) {
  const data = props.data
  const { t } = useTranslation()

  return (
    <>
      <Nav />
      <div className="card card-question">
        <div className={data.cardType}>
          <Title text={data.title} />

          <Content content={data.content} />
        </div>

        {data.legend &&
          <div className="legend">
            <Workdays
              days="X"
              copy={`= # ${t('days')}`}
              size="legend"
            />
          </div> }

      </div>
      <div className={`subcard columns column-${data.subCard.length} text--dark`}>
        <Content content={data.subCard} />
      </div>
    </>
  )
}

Card8.propTypes = {
  data: PropTypes.shape({
    cardType: PropTypes.any,
    content: PropTypes.any,
    legend: PropTypes.any,
    subCard: PropTypes.array,
    title: PropTypes.any
  })
}

export default Card8
