import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Card from './components/Card'

import './assets/Reset.scss'
import './assets/App.scss'

function App () {
  return (
    <div className="App">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route exact path="/" element={<Card />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
