import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Text from '../../ContentItems.jsx/Text.jsx/index.jsx'

import './Card1.scss'

function Card1 (props) {
  const data = props.data
  const queryParams = new URLSearchParams(window.location.search)
  let lang = queryParams.get('lng')

  if (lang === undefined) {
    lang = 'nl'
  }

  return (
    <div className="card startscreen">
      <img src={process.env.PUBLIC_URL + data.image} alt="alt-text" className="cover" />
      <div className="content">
        <h1>{data.title}</h1>
        <Text content={data.text} weight="extrabig" />
        <Link to={`/?screen=${data.linkTo}&lng=${lang}`} className="start">
          <img src={process.env.PUBLIC_URL + data.CTA} alt={data.CTA_copy} />
          <span>{data.CTA_copy}</span>
        </Link>
      </div>
    </div>
  )
}

Card1.propTypes = {
  data: PropTypes.shape({
    CTA: PropTypes.any,
    CTA_copy: PropTypes.any,
    image: PropTypes.any,
    linkTo: PropTypes.any,
    text: PropTypes.any,
    title: PropTypes.any
  })
}

export default Card1
