import PropTypes from 'prop-types'

import Divider from './Divider.jsx/index.jsx'
import Image from './Image.jsx/index.jsx'
import Summary from './Summary.jsx/index.jsx'
import Text from './Text.jsx/index.jsx'
import Workdays from './Workdays.jsx/index.jsx'

function ContentItem (props) {
  const data = props.item

  if (data.type === 'image') {
    return (
      <Image
        src={data.src}
        alt={data.alt}
        caption={data.caption}
        class={data.class}
      />
    )
  } else if (data.type === 'text') {
    return <Text content={data.content} weight={data.weight} />
  } else if (data.type === 'divider') {
    return <Divider direction={data.direction} />
  } else if (data.type === 'workdays') {
    return (
      <Workdays
        title={data.title}
        text={data.text}
        days={data.workdays}
        size={data.size}
        copy={data.workdays_copy}
        style={data.workdays_copy_style}
      />
    )
  } else if (data.type === 'summary') {
    return (
      <Summary
        title={data.title}
        subtitle={data.subtitle}
        borderColor={data.borderColor}
        content={data.content}
      />
    )
  }
}

ContentItem.propTypes = {
  item: PropTypes.shape({
    alt: PropTypes.any,
    borderColor: PropTypes.any,
    caption: PropTypes.any,
    class: PropTypes.any,
    content: PropTypes.any,
    direction: PropTypes.any,
    size: PropTypes.any,
    src: PropTypes.any,
    subtitle: PropTypes.any,
    text: PropTypes.any,
    title: PropTypes.any,
    type: PropTypes.string,
    weight: PropTypes.any,
    workdays: PropTypes.any,
    workdays_copy: PropTypes.any,
    workdays_copy_style: PropTypes.any
  })
}

export default ContentItem
