import PropTypes from 'prop-types'

import './Workdays.scss'

function Workdays (props) {
  const title = props.title
  const text = props.text
  const days = props.days
  const copy = props.copy
  const style = props.style !== undefined ? props.style : ''
  const size = props.size

  if (days !== '0') {
    return (
      <div className={`workdays ${size}`}>
        {text && <p className="workdays-text" dangerouslySetInnerHTML={{ __html: text }}></p>}
        <div className="workdays-infos">
          {title && <span className="workdays-title">{title}</span>}
          <div className="workdays-calendar">
            <div className="workdays-icon"></div>
            <div className="workdays-days">
              <p className="workdays-number">{days}</p>{' '}
              <p
                className={`workdays-copy ${style}`}
                dangerouslySetInnerHTML={{ __html: copy }}
              >
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  } else return <div></div>
}

Workdays.propTypes = {
  copy: PropTypes.string,
  days: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
}

export default Workdays
