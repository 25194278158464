import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import './Nav.scss'

function Nav () {
  const queryParams = new URLSearchParams(window.location.search)

  const navigate = useNavigate()
  const toBegin = window.sessionStorage.getItem('firstId')
  const { t } = useTranslation()
  let lang = queryParams.get('lng')

  if (lang === null) {
    lang = 'nl'
  }

  const onClick = (e) => {
    e.preventDefault()
    navigate(-1)
  }

  return (
    <nav aria-label="Control buttons">
      <ul>
        <li>
          <Link to={`/?lng=${lang}`} onClick={onClick}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t('1 step back')}
          </Link>
        </li>
        <li>
          <Link to={`?screen=${toBegin}&lng=${lang}`}>
            {t('to begin')} <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Nav
