import PropTypes from 'prop-types'

import './Divider.scss'

function Divider (props) {
  const direction = props.direction

  if (direction === 'vertical') {
    return <span className={`divider divider-${direction}`}></span>
  } else if (direction === 'horizontal') {
    const side = props.side
    const color = props.color
    const text = props.text
    const textSize = props.textSize !== undefined ? props.textSize : ''

    return (
      <div className={`divider divider-horizontal ${side} ${color}`}>
        <span className={`text ${textSize}`} dangerouslySetInnerHTML={{ __html: text }}></span>
      </div>
    )
  } else {
    return <div className="divider"></div>
  }
}

Divider.propTypes = {
  direction: PropTypes.string,
  side: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  textSize: PropTypes.string
}

export default Divider
