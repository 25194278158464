import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useControlledTabIndex } from 'react-tab-controller'

import { useRadio } from '../../RadioGroup.jsx'
import Text from '../Text.jsx/index.jsx'

import './Option.scss'

function Option (props) {
  const queryParams = new URLSearchParams(window.location.search)

  const option = props.data
  const place = props.place
  const value = option.text
  const style = option.style !== undefined ? option.style : ''

  const ref = useRef(null)
  const navigate = useNavigate()

  const lang = queryParams.get('lng')

  const { tabIndex, onKeyDown } = useControlledTabIndex(ref, value)
  const [selected, radioOnKeyDown, onClick, onFocus] = useRadio(value)

  const handleKeyDown = (e) => {
    onKeyDown(e)
    radioOnKeyDown(e)

    if (e.key === 'Enter') {
      navigate(`/?screen=${option.linkTo}&lng=${lang}`)
    }
  }

  const handleClick = (e) => {
    onClick(e)

    navigate(`/?screen=${option.linkTo}&lng=${lang}`)
  }

  return (
    <button
      style={{ color: option.color }}
      className={`option ${place} ${style}`}
      role="radio"
      aria-checked={selected}
      tabIndex={tabIndex}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      onFocus={onFocus}
      ref={ref}
    >
      <Icon
        iconInText={option.icon}
        iconAlt={option.text}
        className={`optionIcon optionIcon-${option.color}`}
      />
      <Text content={option.text} weight={`mobile tablet ${option.color}`} />
    </button>
  )
}

Option.propTypes = {
  data: PropTypes.object,
  place: PropTypes.string
}

function Icon (props) {
  const iconInText = props.iconInText
  // const iconAlt = props.iconAlt
  const className = props.className

  // const iconSrc = process.env.PUBLIC_URL + `/assets/img/${iconInText}-icon.svg`

  return (
    <div className={`${className} optionIcon-${iconInText}`}>
      {/* <img src={iconSrc} alt={iconAlt} /> */}
    </div>
  )
}

Icon.propTypes = {
  iconInText: PropTypes.string,
  iconAlt: PropTypes.string,
  className: PropTypes.string
}

export default Option
