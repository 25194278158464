import 'react-app-polyfill/ie11'
import PropTypes from 'prop-types'
import { TabController } from 'react-tab-controller'

import Content from '../../Content.jsx'
import Divider from '../../ContentItems.jsx/Divider.jsx/index.jsx'
import Option from '../../ContentItems.jsx/Option.jsx/index.jsx'
import Title from '../../ContentItems.jsx/Title.jsx/index.jsx'
import Workdays from '../../ContentItems.jsx/Workdays.jsx/index.jsx'
import Nav from '../../Nav.jsx'
import RadioGroup from '../../RadioGroup.jsx'

function Card2 (props) {
  const data = props.data

  const lenghtTreshold = 10
  let optionLength = ''

  if (data.options[0].text.length > lenghtTreshold || data.options[1].text.length > lenghtTreshold) {
    optionLength = 'long'
  }

  return (
    <>
      <Nav />
      <div className="card card-question">
        <div className={data.cardType}>
          <Title text={data.title} />

          <Content content={data.content} />

          <Divider direction="vertical" />

          <TabController>
            <RadioGroup label="">
              <div className={`options ${optionLength}`}>
                <Option data={data.options[0]} place="left" ariaChecked="false" />
                <Divider
                  direction="horizontal"
                  side="left"
                  color={data.options[0].color}
                  text={data.options[0].text}
                />
                <Workdays
                  days={data.workdays}
                  copy={data.workdays_copy}
                  style={data.workdays_copy_style}
                />
                <Divider
                  direction="horizontal"
                  side="right"
                  color={data.options[1].color}
                  text={data.options[1].text}
                />
                <Option
                  data={data.options[1]}
                  place="right"
                  ariaChecked="false"
                />
              </div>
            </RadioGroup>
          </TabController>
        </div>
      </div>
    </>
  )
}

Card2.propTypes = {
  data: PropTypes.shape({
    cardType: PropTypes.any,
    content: PropTypes.any,
    options: PropTypes.any,
    title: PropTypes.any,
    workdays: PropTypes.any,
    workdays_copy: PropTypes.any,
    workdays_copy_style: PropTypes.any
  })
}

export default Card2
