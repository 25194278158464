import 'react-app-polyfill/ie11'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TabController } from 'react-tab-controller'

import Content from '../../Content.jsx'
import Option from '../../ContentItems.jsx/Option.jsx/index.jsx'
import Title from '../../ContentItems.jsx/Title.jsx/index.jsx'
import Workdays from '../../ContentItems.jsx/Workdays.jsx/index.jsx'
import Nav from '../../Nav.jsx'
import RadioGroup from '../../RadioGroup.jsx'

import './Card6.scss'

function Card6 (props) {
  const data = props.data
  const { t } = useTranslation()

  return (
    <>
      <Nav />
      <div className="card card-question">
        <div className={data.cardType}>
          <Title text={data.title} />

          <Content content={data.content} />

          <Workdays
            days={data.workdays}
            copy={data.workdays_copy}
            style={data.workdays_copy_style}
          />

          <TabController>
            <RadioGroup label="">
              <Option data={data.options[0]} place="center" />
            </RadioGroup>
          </TabController>

          {data.legend &&
          <div className="legend">
            <Workdays
              days="X"
              copy={`= # ${t('days')}`}
              size="legend"
            />
          </div> }
        </div>
      </div>
    </>
  )
}

Card6.propTypes = {
  data: PropTypes.shape({
    cardType: PropTypes.any,
    content: PropTypes.any,
    legend: PropTypes.any,
    options: PropTypes.any,
    title: PropTypes.any,
    workdays: PropTypes.any,
    workdays_copy: PropTypes.any,
    workdays_copy_style: PropTypes.any
  })
}

export default Card6
