import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Card1 from './Card1.jsx/index.jsx'
import Card2 from './Card2.jsx/index.jsx'
import Card3 from './Card3.jsx/index.jsx'
import Card5 from './Card5.jsx/index.jsx'
import Card6 from './Card6.jsx/index.jsx'
import Card7 from './Card7.jsx/index.jsx'
import Card8 from './Card8.jsx/index.jsx'
import Card9 from './Card9.jsx/index.jsx'

import './Card.scss'
import './Subcard.scss'

function Card () {
  const queryParams = new URLSearchParams(window.location.search)
  const redirectID = queryParams.get('id')
  const redirectLang = queryParams.get('lang')
  const lang = queryParams.get('lng')
  let id = queryParams.get('screen')
  const navigate = useNavigate()

  useEffect(() => {
    if (redirectID !== null && redirectLang !== null) {
      navigate(`/screen/${redirectID}?lng=${redirectLang}`)
    }
    if (redirectLang !== null) {
      navigate(`/?lng=${redirectLang}`)
    }
    if (redirectID !== null) {
      navigate(`/screen/${redirectID}?lng=nl`)
    }
  })

  // let { id } = useParams()
  let data

  const cardsComponentsList = {
    Card1: Card1,
    Card2: Card2,
    Card3: Card3,
    Card5: Card5,
    Card6: Card6,
    Card7: Card7,
    Card8: Card8,
    Card9: Card9
  }

  if (!id) {
    id = 0
  }

  if (lang === 'fr') {
    data = require('../../assets/cards-fr.json')
  } else if (lang === 'en') {
    data = require('../../assets/cards-en.json')
  } else if (lang === 'de') {
    data = require('../../assets/cards-de.json')
  } else {
    data = require('../../assets/cards-nl.json')
  }

  const currentData = data[id]
  const backgroundColor = currentData.backgroundColor
  const cardId = currentData.cardType.replace('card-', '')
  const DynamicCard = cardsComponentsList[`Card${cardId}`]

  if (cardId === '1') {
    window.sessionStorage.setItem('firstId', id)
  }

  return (
    <div>
      <div className={`container ${backgroundColor}`}>
        <DynamicCard data={currentData} />
      </div>
    </div>
  )
}

export default Card
