import PropTypes from 'prop-types'

function Title (props) {
  const title = props.text

  if (title !== '') return <h2>{title}</h2>
  else return ''
}

Title.propTypes = {
  text: PropTypes.string
}

export default Title
