import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import React from 'react'
import ReactDOM from 'react-dom'
import { initReactI18next } from 'react-i18next'

import './index.css'
import App from './App'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: 'nl',
    ns: ['translations'],
    defaultNS: 'translations',
    load: 'languageOnly',
    debug: false,
    detection: {
      order: ['querystring']
    },
    backend: {
      loadPath: process.env.PUBLIC_URL + '/assets/locales/{{lng}}/translation.json'
    },
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
