import 'react-app-polyfill/ie11'
import PropTypes from 'prop-types'
import { TabController } from 'react-tab-controller'

import Content from '../../Content.jsx'
import Divider from '../../ContentItems.jsx/Divider.jsx/index.jsx'
import Option from '../../ContentItems.jsx/Option.jsx/index.jsx'
import Text from '../../ContentItems.jsx/Text.jsx/index.jsx'
import Title from '../../ContentItems.jsx/Title.jsx/index.jsx'
import Workdays from '../../ContentItems.jsx/Workdays.jsx/index.jsx'
import Nav from '../../Nav.jsx'
import RadioGroup from '../../RadioGroup.jsx'

import './Card7.scss'

function Card7 (props) {
  const data = props.data

  const lenghtTreshold = 10
  let optionLength = ''

  if (data.options[0].text.length > lenghtTreshold || data.options[1].text.length > lenghtTreshold) {
    optionLength = 'long'
  }

  return (
    <>
      <Nav />
      <div className="card card-question">
        <div className={data.cardType}>
          {data.title && <Title text={data.title} />}

          <Content content={data.content} />

          <Divider direction="vertical" />

          <TabController>
            <RadioGroup label="">
              <div className={`options ${optionLength}`}>
                <Option data={data.options[0]} place="left" />

                <Divider
                  direction="horizontal"
                  side="left"
                  color={data.options[0].color}
                  text={data.options[0].text}
                />

                {data.optionDivider &&
                <div className={`divider-center ${data.workdays ? 'divider-top' : ''}`}>
                  <Text content={data.optionDivider} weight={ data.optionDividerStyle !== undefined ? data.optionDividerStyle : 'big'} />

                  {data.workdays && <Workdays
                    days={data.workdays}
                    size="small"
                    copy={data.workdays_copy}
                    style={data.workdays_copy_style}
                                    />}
                </div>
                }

                <Divider
                  direction="horizontal"
                  side="right"
                  color={data.options[1].color}
                  text={data.options[1].text}
                />

                <Option data={data.options[1]} place="right" />
              </div>
            </RadioGroup>
          </TabController>

          {data.workdays && !data.optionDivider && <Workdays
            days={data.workdays}
            size="small"
            copy={data.workdays_copy}
            style={data.workdays_copy_style}
                                                   />}
        </div>
      </div>
    </>
  )
}

Card7.propTypes = {
  data: PropTypes.shape({
    cardType: PropTypes.any,
    content: PropTypes.any,
    optionDivider: PropTypes.any,
    optionDividerStyle: PropTypes.any,
    options: PropTypes.any,
    title: PropTypes.any,
    workdays: PropTypes.any,
    workdays_copy: PropTypes.any,
    workdays_copy_style: PropTypes.any
  })
}

export default Card7
