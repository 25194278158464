import PropTypes from 'prop-types'

import Text from '../Text.jsx/index.jsx'

import './Summary.scss'

function Summary (props) {
  const title = props.title
  const subtitle = props.subtitle !== undefined ? props.subtitle : ''
  const content = props.content.map((item, index) => {
    return <Text key={index} content={item.text} />
  })

  return (
    <div className="summary">
      <div className="summary-title">
        <Text content={title} weight="bold" />
        <Text content={subtitle} weight="extrasmall" />
      </div>
      <div className="summary-content">
        <div>{content}</div>
      </div>
    </div>
  )
}

Summary.propTypes = {
  content: PropTypes.array,
  subtitle: PropTypes.any,
  title: PropTypes.any
}

export default Summary
