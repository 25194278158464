import PropTypes from 'prop-types'

import ContentItem from '../ContentItems.jsx'

function Content (props) {
  const content = props.content

  return (
    <div className="content">
      {content.map((item, i) => (
        <ContentItem key={i} item={item} />
      ))}
    </div>
  )
}

Content.propTypes = {
  content: PropTypes.array
}

export default Content
